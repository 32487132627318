.media-item-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;

  .next-prev-pager {
    align-items: center;
    display: flex;
    gap: 8px;
    justify-content: flex-start;
    margin: 2px 16px -28px;
  }

  .media-actions-bar {
    align-items: center;
    background-color: var(--color-white-100);
    border-radius: var(--border-radius-default);
    display: flex;
    flex: 0 0 64px;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 16px 16px 0;
    padding: 16px;
    width: auto;

    .paging-not-found {
      color: var(--color-gray-59);
      cursor: not-allowed;
      display: flex;
      font-size: var(--iconSizeSmall);
    }

    .media-actions-wrapper,
    .media-actions {
      align-items: flex-start;
      display: flex;
      flex: 1 1 auto;
      gap: 16px;
      justify-content: flex-end;
    }

    .media-actions {
      flex: 0 1 auto;
    }
  }

  .media-status-wrapper {
    align-items: center;
    display: flex;
    gap: 4px;

    button {
      background: var(--color-white-100);
    }

    button:disabled > span:first-of-type {
      color: var(--color-gray-37);
    }
  }

  .media-actions-title {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 6px;

    .media-actions-context {
      align-items: flex-start;
      display: flex;
      gap: 16px;
      justify-content: space-between;

      .media-actions-tags,
      .media-actions {
        align-items: center;
        display: flex;
      }

      .media-actions-tags {
        min-height: 38px;
      }

      .media-actions {
        gap: 8px;
      }
    }
  }

  .status-label {
    align-items: center;
    display: flex;
    gap: 2px;
  }

  .media-item {
    align-content: stretch;
    align-items: stretch;
    display: flex;
    flex: 1 1 100%;
    flex-flow: row wrap;
    gap: 16px;
    margin: 0 16px 16px;
    min-height: 0;
    overflow: hidden;

    .media-item-content {
      @mixin scroll-indicator-top 16px, var(--color-white-100), var(--color-beige-86);
      overflow-y: auto;
      padding: 16px;
      position: relative;
      width: 100%;
    }

    .media-item-medias,
    .media-item-annotations {
      border-radius: var(--border-radius-default);
      display: flex;
      flex: 1;
      flex-direction: column;
      overflow: hidden;
    }

    .media-item-medias {
      flex: 2 1 0;
      height: 100%;

      .media-item-content {
        background-color: var(--color-white-100);
        border-radius: var(--border-radius-zero) var(--border-radius-zero) var(--border-radius-default) var(--border-radius-default);

        .media-item-medias-header {
          @mixin typography-subtitle2;
          align-items: center;
          display: flex;
          flex-direction: row;
          padding: 0 16px 16px 0;

          &:not(:first-child) {
            padding: 16px 16px 0 0;
          }

          svg {
            font-size: var(--iconSizeSmall);
          }
        }
      }
    }

    .media-item-annotations {
      flex: 1 1 0;
      height: 100%;
      min-width: 500px;

      .media-annotations-tabs {
        background-color: var(--color-white-100);
        border-radius: var(--border-radius-default) var(--border-radius-default) var(--border-radius-zero) var(--border-radius-zero);
      }
    }

    .media-item-column-header {
      background-color: var(--color-white-100);
      flex: 0 0 56px;
    }
  }

  .media-articles,
  .media-requests,
  .media-suggestions,
  .media-sources,
  .media-tasks {
    background-color: var(--color-white-100);
    border-radius: var(--border-radius-zero) var(--border-radius-zero) var(--border-radius-default) var(--border-radius-default);
    padding: 16px;

    .empty-list {
      @mixin typography-h5;
      color: var(--color-gray-75);
      padding: 16px;
      text-align: center;
    }
  }

  .media-articles {
    flex: 0 1 auto;
    padding: 0;
  }

  .media-sources {
    .media-sources-header {
      display: flex;
      gap: 6px;
      justify-content: space-between;
      margin: 0 0 16px;

      .media-sources-header-left {
        display: flex;
        flex: 1 1 auto;
        flex-direction: row;
        gap: 6px;

        .media-sources-title {
          display: flex;
          flex: 1 1 auto;
          flex-direction: column;
          gap: 0;
        }
      }

      .media-sources-header-right {
        @mixin typography-caption;
        align-items: flex-end;
        color: var(--color-gray-37);
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
        gap: 6px;
      }
    }
  }

  .media-item-annotations-list {
    li {
      border-bottom: solid 1px var(--color-gray-88);
      padding: 16px 0;

      &:first-of-type {
        padding: 0 0 16px;
      }

      &:last-of-type {
        border-bottom: 0;
      }
    }
  }

  @media (max-width: 1280px) {
    height: 100vh;
    overflow: auto;

    .media-item {
      flex-flow: column nowrap;
      margin: 0;
      overflow: auto;
      padding: 0 16px 16px;

      .media-item-content {
        overflow: visible;
      }

      .media-item-medias,
      .media-item-annotations {
        flex: 1 0 auto;
        height: auto;
        max-height: unset;
        min-width: unset;
      }

      .media-item-annotations {
        overflow: hidden;
      }
    }
  }
}

.media-list {
  .media-list-item {
    padding: 8px 0;

    &:first-of-type {
      padding: 0 0 8px;
    }

    &:last-of-type {
      padding: 8px 0 0;
    }

    &:only-child {
      padding: 0;
    }

    &.media-timeline-item {
      border-bottom: 0;
      padding: 0;
      position: relative;

      &::before {
        background-color: var(--color-gray-88);
        bottom: 0;
        content: '';
        display: block;
        left: 0;
        position: absolute;
        right: auto;
        top: 0;
        width: 1px;
      }
    }
  }
}

.media-list-actions {
  display: flex;
  justify-content: center;
  padding: 16px;
}

.media-item-autocomplete-wrapper {
  display: flex;
  flex-direction: column;
  height: 600px;

  .media-item-autocomplete {
    display: flex;
    flex: 1 1 100%;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;

    .media-item-autocomplete-inputs {
      display: flex;
      flex: 0 0 auto;
      gap: 8px;

      > div {
        flex: 1 1 auto;
      }

      > button {
        flex: 0 0 auto;
        margin: 24px 0 0;
      }
    }

    .media-item-autocomplete-results {
      background-color: var(--color-beige-93);
      border: solid 2px var(--color-beige-86);
      border-radius: var(--border-radius-medium);
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
      gap: 8px;
      overflow-y: scroll;
      padding: 16px;

      .media-item-autocomplete-link {
        display: contents;
        text-decoration: none;
      }

      .media-item-autocomplete-multiple {
        align-items: center;
        display: flex;
      }

      .media-item-autocomplete-selected-item,
      .media-item-autocomplete-selected-item :global(.card) {
        background-color: var(--color-gray-96);
        border-color: var(--color-blue-32);
        outline-color: var(--color-blue-32);
      }
    }
  }
}

.media-slug {
  .media-slug-bullets {
    @mixin typography-body2;
  }
}

.assign-media-dialog-content {
  display: flex;
  gap: 16px;

  .assign-media-dialog-note {
    flex: 1 1 auto;
  }
}

.bulk-assign-media-dialog-note {
  min-width: 450px;
  padding: 16px;
}

.mediaMenuList {
  align-items: center;
  display: flex;
  margin-top: 10px;

  .mediaMenuItem {
    .mediaMenuIcon {
      margin-right: 8px;
      min-width: fit-content !important;
    }

    &:hover {
      background-color: var(--color-blue-98);
      color: var(--color-blue-32);

      .mediaMenuIcon {
        color: var(--color-blue-32);
      }
    }
  }
}

body[dir='rtl'] {
  .media-timeline-item {
    &::before {
      left: auto;
      right: 32px;
    }
  }
}

.disabled-card {
  opacity: .45;

  &:hover {
    opacity: 1;
  }
}
